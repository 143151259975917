import { useService } from '../Container/ContainerContext'
import { useAppContext } from '../Context/AppContext'
import { useTheme } from '../Context/ThemeContext'
import { settingsId } from '../Settings/container'
import { ISettings } from '../Settings/Settings'

export const useImage = (eventId: number | null) => {
    const settings = useService<ISettings>(settingsId)
    const appContext = useAppContext()
    const theme = useTheme()

    const imageUrl = theme.theme.imageUrl || appContext.appSettings?.imageUrl

    let image: string | false

    if (imageUrl) {
        const placeHolders: { [x: string]: string } = { eventid: `${eventId}` }

        image = imageUrl.replace(
            new RegExp('{{ ?([a-z0-9]+) ?}}', 'gi'),
            (_, a: string) => placeHolders[a.toLowerCase()] || ''
        )
    } else if (appContext.appSettings?.pwUrl) {
        image = `${appContext.appSettings?.pwUrl}/resolve/image/${eventId}/1600/760.jpg`
    } else {
        image = `https://peppered-images.itix.nl/resolve/image/${settings.getCode()}/${eventId}/1600/760`
    }

    return {
        status: 'success',
        image: image || appContext.appSettings?.logo,
        defaultImgStyle: image ? {} : { transform: 'scale(0.6)' }
    }
}
