import { find } from 'lodash'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import Button from '../../Components/Button'
import { EventDetailDate } from '../../Components/EventDetail/styled'
import Header from '../../Components/Header'
import { KioskImage } from '../../Components/KioskImage'
import { useService } from '../../Container/ContainerContext'
import { useAppContext } from '../../Context/AppContext'
import { useCart } from '../../Context/CartContext'
import { ThemeContext } from '../../Context/ThemeContext'
import { useOrder } from '../../Hooks/useOrder'
import { EventCategory, IEvent } from '../../Model/Event'
import { ISettings } from '../../Settings/Settings'
import { settingsId } from '../../Settings/container'
import { priceFormatter } from '../../Utils/numbers'
import {
    Content,
    EventButtons,
    EventDuration,
    EventInfo,
    EventLocation,
    EventSelected,
    EventSubTitle,
    EventTitle,
    FinalizeButton,
    FinalizeInfo,
    Image,
    ImageContainer,
    ImageTag,
    NoContent,
    TotalPrice,
    Wrapper
} from './styled'

export const Cart = () => {
    const navigate = useNavigate()
    const { cart } = useCart()
    const appContext = useAppContext()

    let totalPrice = 0

    cart.events.forEach((event) => {
        event.priceCodes.forEach((pc) => {
            if (event.selectedPriceCodes[pc.id]) {
                totalPrice += event.selectedPriceCodes[pc.id] * pc.price
            }
        })
    })

    const { Overlays, setAccountActive, startPayment } = useOrder({
        events: cart.events.map((cartEvent) => ({
            eventId: cartEvent.event.id,
            location: cartEvent.location,
            priceCodes: cartEvent.selectedPriceCodes,
            rank: cartEvent.rank,
            from: cartEvent.event.from
        })),
        totalPrice
    })

    const settings = useService<ISettings>(settingsId)

    const selectedRank =
        cart.events.filter((e) => e.rank !== undefined).length === cart.events.length
    const selectedAmount =
        cart.events.filter((e) => !!find(e.selectedPriceCodes, (s) => s > 0)).length ===
        cart.events.length

    return (
        <>
            <Header
                onBackButton={() => {
                    navigate('/')
                }}
            />

            {cart.events.length === 0 && <NoContent>Geen items in het winkelmandje</NoContent>}

            {cart.events.length > 0 && (
                <Content>
                    {cart.events.map(({ event, rank, priceCodes, selectedPriceCodes }) => {
                        let selectedInfo: { id: number; text: string; price: number }[] = []

                        const rankText = rank === 0 ? 'volloop' : rank + 'e rang'

                        priceCodes.forEach((pc) => {
                            if (selectedPriceCodes[pc.id]) {
                                selectedInfo[pc.id] = {
                                    id: pc.id,
                                    text:
                                        selectedPriceCodes[pc.id] +
                                        'x ' +
                                        rankText +
                                        ' ' +
                                        pc.name +
                                        ' tarief',
                                    price: selectedPriceCodes[pc.id] * pc.price
                                }
                            }
                        })

                        return (
                            <CartEvent key={event.id} event={event} selectedInfo={selectedInfo} />
                        )
                    })}

                    {Overlays}

                    <FinalizeInfo>
                        <TotalPrice>Totaal: {priceFormatter.format(totalPrice)}</TotalPrice>

                        <FinalizeButton
                            disabled={!selectedRank || !selectedAmount}
                            onClick={() => {
                                if (!selectedRank || !selectedAmount) {
                                    return
                                }

                                if (settings.getAnonymousBooking() === 'account') {
                                    setAccountActive(true)
                                } else if (
                                    settings.getAnonymousBooking() === 'sameDayAnonymous' &&
                                    !!cart.events.find(({ event }) => {
                                        return moment(event.from).day() !== moment().day()
                                    })
                                ) {
                                    setAccountActive(true)
                                } else {
                                    startPayment()
                                }
                            }}
                        >
                            {appContext.appSettings?.customText?.orderButton || 'Afrekenen'}
                        </FinalizeButton>
                    </FinalizeInfo>
                </Content>
            )}
        </>
    )
}

const CartEvent = ({ event, selectedInfo }: { event: IEvent; selectedInfo: any }) => {
    const navigate = useNavigate()
    const { removeFromCart } = useCart()
    const settings = useService<ISettings>(settingsId)

    return (
        <ThemeContext event={event}>
            <Wrapper>
                <ImageContainer
                    onClick={() => {
                        navigate('/bestel/' + event.id)
                    }}
                >
                    <Image>
                        <KioskImage eventId={event.id} />
                    </Image>
                    <ImageTag>
                        {event.category === EventCategory.THEATER ? 'Theater' : 'Cinema'}
                    </ImageTag>
                </ImageContainer>
                <div>
                    <EventInfo>
                        <EventTitle>{event.title}</EventTitle>
                        <EventSubTitle>{event.subtitle}</EventSubTitle>
                        <EventLocation>{event.location_name}</EventLocation>
                        {event.until && (
                            <EventDuration>
                                Speelduur: {moment(event.until).diff(event.from, 'minutes')} minuten
                            </EventDuration>
                        )}

                        {settings.getShowDateOnCard() && (
                            <EventDetailDate>
                                {moment(event.from).calendar(null, {
                                    sameDay: '[Vandaag]',
                                    nextDay: '[Morgen]',
                                    nextWeek: 'dddd',
                                    lastDay: '[Gisteren]',
                                    lastWeek: '[Afgelopen] dddd',
                                    sameElse: 'DD MMM YYYY'
                                })}
                            </EventDetailDate>
                        )}

                        <EventSelected>
                            {selectedInfo.map((info) => (
                                <div key={info.id}>
                                    <span>{info.text}</span>{' '}
                                    <span>{priceFormatter.format(info.price)}</span>
                                </div>
                            ))}
                        </EventSelected>
                    </EventInfo>
                    <EventButtons>
                        <Button onClick={() => removeFromCart(event.id)}>Verwijder</Button>
                        <Button
                            onClick={() => {
                                navigate('/bestel/' + event.id)
                            }}
                        >
                            Bewerk
                        </Button>
                    </EventButtons>
                </div>
            </Wrapper>
        </ThemeContext>
    )
}
