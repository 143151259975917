import { forEach, merge } from 'lodash'
import { createContext, ReactNode, useContext, useMemo, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import vm from 'vm-browserify'
import { useService } from '../Container/ContainerContext'
import { IEvent } from '../Model'
import { settingsId } from '../Settings/container'
import { ISettings } from '../Settings/Settings'
import { useAppContext } from './AppContext'

export type IThemeContext = ReturnType<typeof useInitialState>

const useInitialState = ({ event }: { event?: IEvent | null }) => {
    const settings = useService<ISettings>(settingsId)
    const appContext = useAppContext()

    const [theme, setTheme] = useState<IColorScheme>(
        merge(
            getDefaultTheme(),
            settings.getUseColorScheme() === 'custom'
                ? settings.getColorScheme()
                : appContext.appSettings?.colorScheme
        )
    )

    const memoTheme = useMemo(() => {
        const activeTheme = theme.theme

        if (event) {
            theme.showThemes.forEach((showTheme) => {
                if (showTheme.valid) {
                    try {
                        const sandbox = vm.createContext({ context: { event } })
                        vm.runInContext(
                            `const { event } = context; context.result = ${showTheme.code}`,
                            sandbox
                        )

                        if (sandbox.context.result) {
                            forEach(showTheme.theme, (value, key) => {
                                if (value) {
                                    activeTheme[key as keyof IColorScheme['theme']] = value
                                }
                            })
                        }
                    } catch (e) {
                        console.log(e)
                    }
                }
            })
        }

        return activeTheme
    }, [event, theme.showThemes, theme.theme])

    return {
        theme: memoTheme,
        updateTheme: <T extends keyof IColorScheme['theme']>(
            key: T,
            value: IColorScheme['theme'][T]
        ) => {
            setTheme((t) => {
                const theme = { ...t, theme: { ...t.theme, [key]: value } }

                settings.setColorScheme(theme)

                return theme
            })
        },
        showThemes: {
            getShowThemes: () => theme.showThemes,
            addShowTheme: () => {
                setTheme((t) => {
                    const theme = {
                        ...t,
                        showThemes: [
                            ...t.showThemes,
                            {
                                id: Math.max(0, ...t.showThemes.map((t) => t.id)) + 1,
                                code: '',
                                theme: {},
                                valid: false
                            }
                        ]
                    }

                    settings.setColorScheme(theme)

                    return theme
                })
            },
            updateShowThemeCode: ({ id, code }: { id: number; code: string }) => {
                setTheme((t) => {
                    const showTheme = t.showThemes.find((t) => t.id === id)!

                    showTheme.code = code

                    let codeCheck = false

                    if (code) {
                        try {
                            const sandbox = vm.createContext({ context: { event: {} } })
                            vm.runInContext(
                                `const { event } = context; context.result = ${code}`,
                                sandbox
                            )

                            codeCheck = true
                        } catch (e) {
                            console.log(e)
                        }
                    }

                    showTheme.valid = !!code && codeCheck

                    const theme = {
                        ...t,
                        showThemes: [...t.showThemes.filter((t) => t.id !== id), showTheme]
                    }

                    settings.setColorScheme(theme)

                    return theme
                })
            },
            updateShowTheme: <T extends keyof IColorScheme['theme']>({
                id,
                key,
                value
            }: {
                id: number
                key: T
                value: IColorScheme['theme'][T]
            }) => {
                setTheme((t) => {
                    const showTheme = t.showThemes.find((t) => t.id === id)!

                    showTheme.theme[key] = value

                    const theme = {
                        ...t,
                        showThemes: [...t.showThemes.filter((t) => t.id !== id), showTheme]
                    }

                    settings.setColorScheme(theme)

                    return theme
                })
            },
            removeShowTheme: ({ id }: { id: number }) => {
                setTheme((t) => {
                    const theme = {
                        ...t,
                        showThemes: t.showThemes.filter((t) => t.id !== id)
                    }

                    settings.setColorScheme(theme)

                    return theme
                })
            }
        }
    }
}

const Context = createContext<IThemeContext>({} as IThemeContext)

export const ThemeContext = ({
    children,
    event
}: {
    children: ReactNode
    event?: IEvent | null
}) => {
    const initialState = useInitialState({ event })

    return (
        <Context.Provider value={initialState}>
            <ThemeProvider theme={initialState.theme}>{children}</ThemeProvider>
        </Context.Provider>
    )
}

export const useTheme = () => useContext(Context)

// Stuff

export const showOverridableColorScheme = [
    'disabledButtonColor',
    'inactiveButtonColor',
    'buttonColor',
    'disabledButtonBorderColor',
    'inactiveButtonBorderColor',
    'buttonBorderColor',
    'imageTagColor'
]

export const showOverridableColorSchemeText = ['locationImg', 'imageUrl']

export type IColorScheme = ReturnType<typeof getDefaultTheme>

const getDefaultTheme = () => {
    const theme = {
        disabledButtonColor: '#808080',
        inactiveButtonColor: '#ffffff',
        buttonColor: '#0074c0',

        disabledButtonBorderColor: '#808080',
        inactiveButtonBorderColor: '#000000',
        buttonBorderColor: '#0074c0',

        finalizeButtonColor: '#d40f14',
        finalizeButtonBorderColor: '#d40f14',

        imageTagColor: '#0074c0',

        spinnerColor: '#d40f14',
        progressbarColor: '#d40f14',

        popupInfoColor: '#0074c0',
        popupSuccessColor: '#008000',
        popupErrorColor: '#d40f14',

        locationImg: '',
        imageUrl: ''
    }

    const showThemes: {
        id: number
        code: string
        theme: Partial<IColorScheme['theme']>
        valid: boolean
    }[] = []

    return { theme, showThemes }
}

// if (event.category === EventCategory.THEATER) {
//     activeTheme.buttonColor = '#d40f14'
//     activeTheme.buttonBorderColor = '#d40f14'

//     activeTheme.imageTagColor = '#d40f14'
// }

// if (event.category === EventCategory.FILM) {
//     activeTheme.buttonColor = '#0074c0'
//     activeTheme.buttonBorderColor = '#0074c0'

//     activeTheme.imageTagColor = '#0074c0'
// }
